function FurniturePlanning() {
  return (
    <div className="content services-view">
      <h3>Проектирование умной мебели</h3>
      <p>
        Лучшее время для обращения ко мне за помощью - стадия планирования
        мебели (шкаф с одеждой, детский шкаф, кухня и тд) Так ты сможешь
        получить удобную, индивидуальную мебель, которая будет учитывать все
        твои потребности и у каждой вещи изначально будет свое самое удобное
        место.
      </p>
      <p>Эта услуга оказывается онлайн.</p>
      <p>
        Мы обговариваем твой запрос, стоимость услуги, условия оплаты и после
        внесения предоплаты (50%), назначаем технический видеозвонок.
        <br />В разговоре я провожу интервью, обсуждаем план, дизайн-проект
        (если есть), кто будет делать мебель и тд. После вы высылаете мне
        фото-видеоматериалы и замеры. А я высылаю специальную анкету, с помощью
        которой мы составим абсолютно точный и полный список вещей, которые
        предполагается хранить. Это может быть немного муторным, но от этого
        зависит успех конечного продукта.
      </p>
      <p>
        После получения мной заполненной анкеты, я продумываю твой проект, мы
        его правим, согласовываем и затем я его оформляю в итоговую презентацию.
        В ней будет 2d и 3d визуализация, размеры, план размещения вещей,
        розеток, список необходимых организационных материалов и мои
        рекомендации.
      </p>
      <p>
        После получения презентации, вносишь оставшиеся 50% оплаты за мои услуги
        и идешь с этим проектом в мебельную компанию для просчета итоговой
        стоимости, либо в магазин корпусной мебели.
      </p>
      <p className="red">
        Стоимость услуги:
        <ul>
          <li>
            70 евро за погонный метр, если мебель делается мебельной фирмой на
            заказ
          </li>
          <li>50 евро за погонный метр, если это мебель IKEA</li>
          <li>150 евро план расстановки мебели</li>
        </ul>
      </p>
    </div>
  );
}

export default FurniturePlanning;
