function FieldConsultation() {
  return (
    <div className="content services-view">
      <h3>Выездная организация пространства</h3>
      <p>
        Эту услугу я оказываю тебе в твоем доме, соответственно здесь первый
        важный фактор – географическая удаленность от меня. Если дорога занимает
        больше 1 часа, то я предложу тебе:
      </p>
      <p>
        <ul className="marker-list">
          <li>поработать онлайн (читай описание онлайн организации)</li>
          <li>оплату времени в дороге и гостиницы</li>
          <li>выезд двух организаторов пространства</li>
        </ul>
      </p>
      <p>
        Если ты живешь близко, то мы договариваемся о дате первого моего
        технического выезда. На этой встрече мы знакомимся, присматриваемся, я
        провожу интервью, ты рассказываешь мне о своих проблемах и пожеланиях, я
        делаю необходимые замеры помещения, мебели и фото/видео.
      </p>
      <p>
        Если после технического выезда ты принимаешь решение о дальнейшем
        сотрудничестве, то этот выезд не оплачивается. Если понимаешь, что
        сотрудничества не будет, на этом этапе можно спокойно отказаться,
        оплатив мое время по тарифу 50 евро в час.
      </p>
      <p>
        После технического выезда, я дома составляю Индивидуальный План
        Организации (ИПО). Согласовываю основные моменты и пишу список
        необходимых покупок. Организационные материалы ты можешь закупить сама
        по моему списку или делегировать это мне. (Цена будет договорная, потому
        что многое зависит от магазина, онлайн или офлайн и объемов закупки)
      </p>
      <p>
        Когда все оргматериалы будут закуплены мы договариваемся о дате и
        времени моего следующего выезда к тебе на расхламление и организацию
        вещей. Расхламление проводится с хозяином организуемой зоны. Исключением
        могут быть аптечка, продукты, косметика и т.п., где нужно только
        проверить сроки годности, а не необходимость иметь эту вещь. Организацию
        я провожу в соответствии с составленным ранее планом. Здесь твое
        присутствие не обязательно. Время расхламления и организации зависит от
        скорости принятия тобой решений и объемов вещей.
      </p>
      <p>
        <span className="red">Стоимость услуги:<br></br>
              1-3 часа - 70 евро в час<br></br>
              3-6 часов - 60 евро в час<br></br>
              более 6 часов - 50 евро в час
        </span>
        <br />
        Оплачивается только время моей работы у тебя дома.
        <br />
        Если не успели все за один выезд, назначаем повторный.
        <br />
      </p>
      <p>
        После того как зона организована, у тебя есть 2 бесплатные недели
        поддержки в Вотсапе, чтобы внести поправки, если где-то что-то окажется
        не удобным.
      </p>
      <p>
        По истечении этих двух недель можно продлить поддержку по цене 20 евро в
        неделю.
      </p>
    </div>
  );
}

export default FieldConsultation;
