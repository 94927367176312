import MainImg from "../assets/mainphoto.jpg";

function Main() {
  return (
    <div className="content about">
      <img className="main-photo" alt="about" src={MainImg} />
      <div>
      <p>
        Привет!
      </p>
      <p>
        Рада видеть тебя здесь!
      </p>
      <p>
        Меня зовут Диана, я дипломированный организатор пространства и специалист по планированию мебели. Я помогаю создавать уют и порядок в доме, который работает на тебя.
      </p>
      <br></br>
      <ul>
        <li> - Уборка занимает минимум времени.</li>
        <li> - Каждый член семьи знает, где что лежит.</li>
        <li> - Больше нет хаоса, опозданий и лишних трат.</li>
      </ul>
      <br></br>
      <p>
        Я разработаю для тебя индивидуальную систему хранения, которая сделает твой дом функциональным и стильным. 
      </p>
      <p >
        Давай начнем создавать уют уже сегодня!
      </p>
      </div>
    </div>
  );
}

export default Main;
