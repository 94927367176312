function OnlineConsultation() {
  return (
    <div className="content services-view">
      <h3>Онлайн консультация “Взгляд профессионала”</h3>
      <p>Кому и зачем?</p>
      <p>
        Если у тебя локальные проблемы с порядком, хочется услышать мнение
        профессионала, не понимаешь входит ли твой вопрос в сферу моих услуг,
        хочешь что-то обсудить — бери простую онлайн консультацию.
      </p>
      <p>
        Мы договариваемся об удобном времени для видеозвонка в Zoom. Ты ничего
        заранее не готовишь, главное, чтобы никто не отвлекал от разговора и ты
        могла показать нужную зону.
      </p>
      <p>
        Во время звонка рассказываешь и показываешь свои печали, а я со своей
        стороны объясняю причины и придумываю возможные способы решения.
        Длительность звонка зависит от тебя. Звонок длительностью менее 15 минут
        не оплачивается. По окончании звонка я пришлю запись нашего разговора,
        потому что обычно в процессе такой беседы возникает много идей,
        вариантов решения проблем и можно легко что-то упустить.
      </p>
      <p className="red">
        Стоимость онлайн консультации "Взгляд профессионала" – 50 евро в час.
      </p>
    </div>
  );
}

export default OnlineConsultation;
